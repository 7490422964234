import React from "react";

const omsPrivateRoute = [
  {
    uniqueKey: "oms.dashboard",
    pathName: "/dashboard",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],


    componentName: React.lazy(() => import("../view/dashboard")),
  },

  {
    uniqueKey: "oms.registration",
    pathName: "/registration",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],


    componentName: React.lazy(() => import("../view/registration")),
  },
  {
    uniqueKey: "oms.planned-outage",
    pathName: "/planned-outage",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],


    componentName: React.lazy(() => import("../view/plannedOutage")),
  },
  {
    uniqueKey: "oms.generate-planned-outage",
    pathName: "/generate-planned-outage",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    
    componentName: React.lazy(() => import("../view/generatePlannedOutages")),
  },
  {
    uniqueKey: "oms.complaint-registration",
    pathName: "/complaint-registration",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],


    componentName: React.lazy(() => import("../view/complaintRegistration")),
  },
  {
    uniqueKey: "oms.complaints",
    pathName: "/complaints",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    componentName: React.lazy(() => import("../view/complaints")),
  },
  {
    uniqueKey: "oms.confirmed-outages",
    pathName: "/confirmed-outages",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    componentName: React.lazy(() => import("../view/confirmedOutages")),
  },
  {
    uniqueKey: "oms.user-approval",
    pathName: "/user-approval",
    role: ['SUPERADMIN'],


    componentName: React.lazy(() => import("../view/UserApproval")),
  },
  {
    uniqueKey: "oms.user-reject",
    pathName: "/all-approved-users",
    role: ['SUPERADMIN'],


    componentName: React.lazy(() => import("../view/approvedUser")),
  },
  {
    uniqueKey: "oms.user-registration",
    pathName: "/user-registration",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],


    componentName: React.lazy(() => import("../view/userRegistration")),
  },
  {
    uniqueKey: "oms.user-detail",
    pathName: "/user-detail",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    componentName: React.lazy(() =>
      import("../view/UserProfile/Component/UserDetails")
    ),
  },
  {
    uniqueKey: "oms.reset-password",
    pathName: "/reset-password",
    role: ['SUPERADMIN' , 'DEPARTMENTAL' , 'CRM'],

    componentName: React.lazy(() =>
      import("../view/UserProfile/Component/ResetPassword")
    ),
  },
  {
    uniqueKey: "oms.follow-up",
    pathName: "/follow-up",
    role: ['SUPERADMIN' , 'DEPARTMENTAL'],

    componentName: React.lazy(() => import("../view/complaintFollowUp")),
  },
  {
    uniqueKey: "oms.assigned-crew",
    pathName: "/assigned-crew",
    role: ['SUPERADMIN' , 'DEPARTMENTAL'],

    componentName: React.lazy(() => import("../view/CrewAssigned")),
  },
  {
    uniqueKey: "oms.support",
    pathName: "/all-support",
    role: ['SUPERADMIN'],


    componentName: React.lazy(() => import("../view/Support")),
  },
  {
    uniqueKey: "oms.billingDataForm",
    pathName: "/billingform",
    role: ['SUPERADMIN', 'DEPARTMENTAL', 'CRM'],


    componentName: React.lazy(() => import("../view/billing")),
  },
  {
    uniqueKey: "oms.billingTable",
    pathName: "/billingTable",
    role: ['SUPERADMIN', 'DEPARTMENTAL', 'CRM'],


    componentName: React.lazy(() => import("../view/billingTable")),
  }
];

export default omsPrivateRoute;
