import React from 'react'



const portalConfig = {
    authenticateEntryForAdmin:"/billingform",
    authenticateEntry:"/billingform",
    unAuthenticateEntry:"/sign-in"
}

export default portalConfig
