import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Services/AuthServices";
import {
  OnSignIn,
  onSignOut,
  setToken,
  setUserAreaDet,
  setUserDet,
} from "../store/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import { getUserAreaDeatils } from "../Services/ApiCalling";

function useAuth() {
  const token = useSelector((state) => state.auth.token);
  console.log("tiokenn", token);
  const isSignedIn = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  // console.log("OnSignInOnSignIn",OnSignIn)
  const signIn = async (email, password) => {
    console.log("passwordpassword", email, password);
    const response = await login(email, password);
    console.log("responseresponse", response);
    if (response.Success) {
      dispatch(OnSignIn(response.Body));
      if (response.Body) {
        let areaDet = await getUserAreaDeatils(response.Body);
        console.log("areaDetareaDetareaDetareaDetareaDet", areaDet);
        dispatch(setUserAreaDet(areaDet.data));
      }

      const decodedToken = jwtDecode(response.Body);

      let currTime = new Date().getTime();
      // currTime = currTime + 7200000; 
      currTime = currTime + 36000000; //36,000,000

      localStorage.setItem("tokenExpTime", currTime.toString());

      console.log("decodeddd tokennn", decodedToken);
      dispatch(setUserDet(decodedToken));
      return response;
    } else if (response.Error) {
      console.log("else condition");
      return response;
    }
  };

  const signOut = () => {
    localStorage.removeItem("tokenExpTime");
    dispatch(onSignOut());
    console.log("token after signout", token);
  };

  return { token, signIn, signOut, isSignedIn };
}

export default useAuth;
